import React, { useRef } from 'react';
import { Vector3 } from '@babylonjs/core/Maths/math';
import BabylonScene from 'babylonjs-hook';
import '../layout.css';

import {
    GlowLayer,
    PointLight,
    StandardMaterial,
    TransformNode,
}
    from "@babylonjs/core";
import {Color3} from "@babylonjs/core/Maths/math";
import {ArcRotateCamera} from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";
import {MeshBuilder} from "@babylonjs/core/Meshes/meshBuilder";
import {ActionManager} from "@babylonjs/core/Actions/actionManager";
import {ExecuteCodeAction} from "@babylonjs/core/Actions";

let box1;
let babylonLink;


let boxMaps;
let boxDraw;
let boxMain;
let camera;



const onSceneReady = scene => {

    // scene.clearColor = new Color3(73, 80, 87); was this one
    scene.clearColor = new Color3(0.0234375,0.0234375,0.0234375); // CHECK

    const canvas = scene.getEngine().getRenderingCanvas();

    camera = new ArcRotateCamera("cam", -Math.PI /2, Math.PI / 2, 5, new Vector3(0,0,4));
    const anchor = new TransformNode("");

    camera.wheelDeltaPercentage = 0.05;
    camera.lowerRadiusLimit = 5;
    camera.upperRadiusLimit = 8;
    // camera.lowerBetaLimit = -Math.PI + -Math.PI /2;



    camera.attachControl(canvas, true);


    const light = new PointLight('', new Vector3(0, 0, 0), scene);
    light.intensity = 0.5;
    light.diffuse.set(1, 0.8, 0.9); // mood

    ///////////////////////////

    boxDraw = MeshBuilder.CreateBox("box", {height: 0.7, width: 0.7, depth: 0.7});
    boxDraw.position.y = 0;
    boxDraw.position.z = 504;
    boxDraw.position.x = 0;

    ///////////////////////////

    boxMaps = MeshBuilder.CreateBox("boxMaps", {height: 0.7, width: 0.7, depth: 0.7});
    boxMaps.position.y = 1;
    boxMaps.position.z = 1004;
    boxMaps.position.x = 0;


    box1 = MeshBuilder.CreateBox("box1", {size: 0.7}, scene);
    box1.position.y = 0;
    box1.position.z = 5;
    box1.position.x = 0;

    boxMain = MeshBuilder.CreateBox("boxMain", {size: 0.7}, scene);
    boxMain.position.y = 0;
    boxMain.position.z = 250;
    boxMain.position.x = 0;


    var boxMaterial = new StandardMaterial("material", scene);
    //boxMaterial.emissiveColor = new Color3(0, 0.58, 0.86);
    boxMaterial.emissiveColor = new Color3(0.15, 0.15, 0.86);

    // Glowing effect
    var gl = new GlowLayer("glow", scene);



    boxDraw.material = boxMaterial;
    box1.material = boxMaterial;
    boxMaps.material = boxMaterial;

    boxMain.material = boxMaterial;








    // Panel Draw
    ///////////////////////////////////////////

    const manager1 = new GUI.GUI3DManager(scene);

    // SpherePanelを生成
    const panel1 = new GUI.SpherePanel();
    panel1.margin = 0.8; // 0.4
    panel1.radius = 3.5;

    manager1.addControl(panel1);
    panel1.linkToTransformNode(anchor);
    panel1.position.z = 250;  // -1.5
    panel1.columns = 2;




    const buttonDark = new GUI.HolographicButton("darkDraw");
    panel1.addControl(buttonDark);

    var textDark = new GUI.TextBlock();
    textDark.text = "Dunkel";
    textDark.color = "#2626DB";
    textDark.fontSize = 82;
    textDark.fontWeight = "bold";

    buttonDark.content = textDark;


    buttonDark.onPointerClickObservable.add(function() {
        window.open("/draw/draw-dark/",'_self',false);
    });




    const buttonLight = new GUI.HolographicButton("lightDraw");
    panel1.addControl(buttonLight);

    var textLight = new GUI.TextBlock();
    textLight.text = "Light";
    textLight.color = "#2626DB";
    textLight.fontSize = 82;
    textLight.fontWeight = "bold";

    buttonLight.content = textLight;


    buttonLight.onPointerClickObservable.add(function() {
        window.open("/draw/draw-light/",'_self',false);
    });



    const buttonDa = new GUI.HolographicButton("DrawDa");
    panel1.addControl(buttonDa);

    var textDa = new GUI.TextBlock();
    textDa.text = "Dark";
    textDa.color = "#2626DB";
    textDa.fontSize = 82;
    textDa.fontWeight = "bold";

    buttonDa.content = textDa;


    buttonDa.onPointerClickObservable.add(function() {
        window.open("/draw/draw-dark/",'_self',false);
    });
    ////////////////////////////////////////////////////////



    // Panel Maps
    ///////////////////////////////////////////
    const managerMaps = new GUI.GUI3DManager(scene);

    // SpherePanelを生成
    const panelMaps = new GUI.SpherePanel();
    panelMaps.margin = 0.4;
    panelMaps.radius = 3.5;

    managerMaps.addControl(panelMaps);
    panelMaps.linkToTransformNode(anchor);
    panelMaps.position.z = 1000;  // -1.5
    panelMaps.position.y = 1;
    panelMaps.columns = 2;


    const buttonFuture = new GUI.HolographicButton("future");
    panelMaps.addControl(buttonFuture);

    var textFuture = new GUI.TextBlock();
    textFuture.text = "Future";
    textFuture.color = "#034067";
    textFuture.fontSize = 74;
    textFuture.fontWeight = "bold";

    buttonFuture.content = textFuture;

    buttonFuture.onPointerClickObservable.add(function() {

        window.open("/maps/where-to-go/",'_self',false);
    });





    const buttonStories = new GUI.HolographicButton("stories");
    panelMaps.addControl(buttonStories);

    var textStories = new GUI.TextBlock();
    textStories.text = "Stories";
    textStories.color = "#2626DB";
    textStories.fontSize = 74;
    textStories.fontWeight = "bold";

    buttonStories.content = textStories;


    buttonStories.onPointerClickObservable.add(function() {

        window.open("/trips/",'_self',false);
    });


    const buttonRecommended = new GUI.HolographicButton("recommended");
    panelMaps.addControl(buttonRecommended);

    var textRecommended = new GUI.TextBlock();
    textRecommended.text = "Places";
    textRecommended.color = "#2626DB";
    textRecommended.fontSize = 74;
    textRecommended.fontWeight = "bold";

    buttonRecommended.content = textRecommended;


    buttonRecommended.onPointerClickObservable.add(function() {

        window.open("/maps/recommended/",'_self',false);
    });



    const buttonPast = new GUI.HolographicButton("past");
    panelMaps.addControl(buttonPast);

    var textPast = new GUI.TextBlock();
    textPast.text = "Past";
    textPast.color = "#034067";
    textPast.fontSize = 74;
    textPast.fontWeight = "bold";

    buttonPast.content = textPast;


    buttonPast.onPointerClickObservable.add(function() {

        window.open("/maps/geoarchive/",'_self',false);
    });


    ////////////////////////////////////////////////////////





    ///////////////////////////
    const manager = new GUI.GUI3DManager(scene);

    // SpherePanelを生成
    const panel = new GUI.SpherePanel();
    panel.margin = 0.4;
    panel.radius = 3.5;

    manager.addControl(panel);
    panel.linkToTransformNode(anchor);
    panel.position.z = 1.2;  // -1.5
    panel.columns =2;



    // Materials
    var webMaterial = new StandardMaterial("webmaterial", scene);
    //boxMaterial.emissiveColor = new Color3(0, 0.58, 0.86);
    webMaterial.emissiveColor = new Color3(0.15, 0.15, 0.86);




    const buttonTravel = new GUI.HolographicButton("web");
    panel.addControl(buttonTravel);

    var textTravel = new GUI.TextBlock();
    textTravel.text = "Draw";
    textTravel.color = "#2626DB";
    textTravel.fontSize = 82;
    textTravel.fontWeight = "bold";

    buttonTravel.content = textTravel;


    buttonTravel.onPointerClickObservable.add(function() {
        camera.setTarget(boxMain)
        // window.open("/interactive/");
    });


    const buttonWork = new GUI.HolographicButton("tech");
    panel.addControl(buttonWork);

    var textWork = new GUI.TextBlock();
    textWork.text = "Work";
    textWork.color = "#2626DB";
    textWork.fontSize = 82;
    textWork.fontWeight = "bold";

    buttonWork.content = textWork;


    buttonWork.onPointerClickObservable.add(function() {
        // camera.setTarget(boxMain);

        window.open("/projects/");
    });




    const buttonMaps = new GUI.HolographicButton("maps");
    panel.addControl(buttonMaps);

    var textMaps = new GUI.TextBlock();
    textMaps.text = "Maps";
    textMaps.color = "#2626DB";
    textMaps.fontSize = 82;
    textMaps.fontWeight = "bold";

    buttonMaps.content = textMaps;

    buttonMaps.onPointerClickObservable.add(function() {

        camera.setTarget(boxMaps);
        // window.open("./menu/menu-maps");
    });




    // PANEL FOR THE WEBGL

    const buttonDraw = new GUI.HolographicButton("draw");
    panel.addControl(buttonDraw);

    var textDraw = new GUI.TextBlock();
    textDraw.text = "Play";
    textDraw.color = "#2626DB"; //#034067
    textDraw.fontSize = 82;
    textDraw.fontWeight = "bold";
    textDraw.fontFamily = "Helvetica"

    buttonDraw.content = textDraw;


    buttonDraw.onPointerClickObservable.add(function() {
        camera.setTarget(boxDraw);
        // window.open("./menu/menu-draw");
    });

    ///////////////// PLAY PANEL ////////////////////// ////////////////////////////

    const managerPlay = new GUI.GUI3DManager(scene);

    // SpherePanelを生成
    const panelPlay = new GUI.SpherePanel();
    panelPlay.margin = 0.8; // 0.4
    panelPlay.radius = 3.5;

    managerPlay.addControl(panelPlay);
    panelPlay.linkToTransformNode(anchor);
    panelPlay.position.x = 0;
    panelPlay.position.y = 0;  // -1.5

    panelPlay.position.z = 500;  // -1.5

    panelPlay.columns = 2;

    ///// BUTTON FOR LONDON
    const buttonLondon = new GUI.HolographicButton("ButtonLondon");
    panelPlay.addControl(buttonLondon);

    var textLondon = new GUI.TextBlock();
    textLondon.text = "London";
    textLondon.color = "#2626DB";
    textLondon.fontSize = 64;
    textLondon.fontWeight = "bold";

    buttonLondon.content = textLondon;


    buttonLondon.onPointerClickObservable.add(function() {
        window.open("/interactive/",'_self',false);  // 27.11.2021
    });

    ///////////////// BUTTON WALL RUSH ////////////

    const buttonWallRush = new GUI.HolographicButton("ButtonWallRush");
    panelPlay.addControl(buttonWallRush);

    var textWallRush = new GUI.TextBlock();
    textWallRush.text = "Wall";
    textWallRush.color = "#2626DB";
    textWallRush.fontSize = 64;
    textWallRush.fontWeight = "bold";

    buttonWallRush.content = textWallRush;


    buttonWallRush.onPointerClickObservable.add(function() {
        window.open("/scenes/wall-rush/",'_self',false);  // 27.11.2021
    });

    ///////////////// MAIN PANEL /////////////////////

    const managerMain = new GUI.GUI3DManager(scene);

    // SpherePanelを生成
    const panelMain = new GUI.SpherePanel();
    panelMain.margin = 0.8; // 0.4
    panelMain.radius = 3.5;

    managerMain.addControl(panelMain);
    panelMain.linkToTransformNode(anchor);
    panelMain.position.x = 0;
    panelMain.position.y = 250;  // -1.5

    panelMain.position.z = 0;  // -1.5

    panelMain.columns = 2;


    const buttonAbout = new GUI.HolographicButton("ButtonMe");
    panelMain.addControl(buttonDark);

    var textAbout = new GUI.TextBlock();
    textAbout.text = "Me";
    textAbout.color = "#2626DB";
    textAbout.fontSize = 82;
    textAbout.fontWeight = "bold";

    buttonAbout.content = textAbout;


    buttonAbout.onPointerClickObservable.add(function() {
        window.open("/resume/",'_self',false);  // 27.11.2021
    });


    const buttonThings = new GUI.HolographicButton("ButtonThings");
    panelMain.addControl(buttonThings);

    var textThings = new GUI.TextBlock();
    textThings.text = "Things";
    textThings.color = "#2626DB";
    textThings.fontSize = 82;
    textThings.fontWeight = "bold";

    buttonThings.content = textThings;


    buttonThings.onPointerClickObservable.add(function() {
        window.open("/projects/",'_self',false);
    });



    // buttonWeb.actionManager = new ActionManager(scene);
    // buttonWeb.actionManager.registerAction(new ExecuteCodeAction(
    //     ActionManager.onPointerClickObservable ,
    //     function (evt) {
    //
    //         //move the box upright
    //         buttonWeb.position.x += 0.1;
    //         buttonWeb.position.y += 0.1;
    //
    //         window.open("http://twitter.com/");
    //         //update the color
    //
    //     }));

    // const buttonTravel = new GUI.HolographicButton("travel");
    // panel.addControl(buttonTravel);
    // // buttonTravel.text = "Tech";
    // buttonTravel.imageUrl = "../../../images/CroppedPic.png"




    panel.blockLayout = false;

    // let Writer = MeshWriter(scene, {scale:0.5});
    // let text1  = new Writer(
    //     "AÁÀÃÂÄÅÆBCDEÉÈÊËFGH",
    //     {
    //         "anchor": "center",
    //         "letter-height": 40,
    //         "color": "#1C3870",
    //         "position": {
    //             "z": 70
    //         }
    //     }
    // );


    boxDraw.actionManager = new ActionManager(scene);
    boxDraw.actionManager.registerAction(
        new ExecuteCodeAction(
            ActionManager.OnPickTrigger,
            () => {
                camera.setTarget(box1);
                camera.position = new Vector3(0,0,4);
            }
        )
    );


    boxMaps.actionManager = new ActionManager(scene);
    boxMaps.actionManager.registerAction(
        new ExecuteCodeAction(
            ActionManager.OnPickTrigger,
            () => {
                camera.setTarget(box1);
                camera.position = new Vector3(0,0,4);
            }
        )
    );

    boxMain.actionManager = new ActionManager(scene);
    boxMain.actionManager.registerAction(
        new ExecuteCodeAction(
            ActionManager.OnPickTrigger,
            () => {
                camera.setTarget(box1);
                camera.position = new Vector3(0,0,4);
            }
        )
    );

}

/**
 * Will run on every frame render.  We are spinning the box on y-axis.
 */

const onRender = scene => {

    let deltaTimeInMillis;
    if (box1 !== undefined && boxMaps !== undefined && boxDraw !== undefined) {
        deltaTimeInMillis = scene.getEngine().getDeltaTime();

        const rpm = 10;
        box1.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
        box1.rotation.z += ((rpm / 100) * Math.PI * 2 * (deltaTimeInMillis / 1000));

        boxMaps.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
        boxMaps.rotation.z += ((rpm / 100) * Math.PI * 2 * (deltaTimeInMillis / 1000));

        boxDraw.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
        boxDraw.rotation.z += ((rpm / 100) * Math.PI * 2 * (deltaTimeInMillis / 1000));

        boxMain.rotation.z += ((rpm / 100) * Math.PI * 2 * (deltaTimeInMillis / 1000));

        camera.position.x += 0.5* ((rpm / 100) * Math.PI * 2 * (deltaTimeInMillis / 1000))


    }


    // if (camera !== undefined) {
    //     deltaTimeInMillis = scene.getEngine().getDeltaTime();
    //
    //     const rpm = 10;
    //     camera.rotation.y += ((rpm / 80) * Math.PI * 2 * (deltaTimeInMillis / 1000));
    // }

}

export default () => {
    babylonLink = useRef(null);
    return (
        <>
            <BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />
            <a ref={babylonLink} target="_self" rel="noopener noreferrer" href="https://pavli.netlify.app/">
            </a>
            {/*This works*/}
            {/*<BabylonScene antialias onSceneReady={onSceneReady} onRender={onRender} id='render-canvas' />*/}
        </>
    )
}
