import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scene from "../components/scenes/scene";
// import ProfilePic from "../components/cropped-profile"
import SceneButton from "../components/scenes/control-room"

// import GameSwitch from "../components/games/GameSwitch";
import CookieConsent from "react-cookie-consent";


// import ekb from '../images/draw-ekb-dark.png'
// import me from '../images/lookingup-circle.png'
// import handsquare from "../images/draw-hand-dark-square.png"

import meMasked from "../images/meMasked.png"
import meYellow from "../images/meYellow.png"
import meLooking from "../images/meLooking.jpg"
import meChilling from "../images/meChilling.jpg"
import meChilling1 from "../images/meChilling1.jpg"
import meDrinking from "../images/meDrinking.jpg"

// import meFucking from "../images/meFucking.jpg"

import meLookingMuc from "../images/meLooking1.jpeg"

import GenovaNervi from "../images/genova-nervi.jpg"
import Cern from "../images/cern.jpg"

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import {FaTelegram} from "react-icons/fa";


class Collapsible extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            open: false
        }
        this.togglePanel = this.togglePanel.bind(this);
    }

    togglePanel(e){
        this.setState({open: !this.state.open})
    }

    componentDidUpdate(){
    }

    render() {
        return (<div>
            <div onClick={(e)=>this.togglePanel(e)} className='headered'>
                {this.props.title}</div>
            {this.state.open ? (
                <div className='contented'>
                    {this.props.children}
                </div>
            ) : null}
        </div>);
    }
}


// making pictures randomizer

const divs = [
    { id: 1, src: 'Cern', caption: 'Caption 1' },
    { id: 2, src: 'meMasked', caption: 'Caption 2' },
    { id: 3, src: 'meYellow', caption: 'Caption 3' },
    { id: 4, src: 'meChilling', caption: 'Caption 4' },
    { id: 4, src: 'meChilling1', caption: 'Caption 4' },
    { id: 4, src: 'meDrinking', caption: 'Caption 4' }
];
const shuffledDivs = divs.sort(() => Math.random() - 0.5);
//


const IndexPage = () => (
    <Layout>
        <script data-goatcounter="https://metrics.goatcounter.com/count"
                async src="//gc.zgo.at/count.js"></script>
        <SEO title="Pavel" />
        <br />
        <br />

            <div style={{margin: `0 auto 0 auto`, textAlign:`center`, padding:`0`}}>
                <h1 style={{margin: `0 auto`, textAlign:`center`, padding:`0`}}>
                    Pavel's Web
                </h1>
                <h1 style={{margin: `0 auto`, textAlign:`center`, padding:`0`}} className={'gradient'}>
                    Playground
                </h1>
            </div>

        <br />
        <br />
        {/*<br />*/}
        {/*<br />*/}
        {/*<br />*/}

        {/*<div style={{*/}
        {/*    display:`flex`,*/}
        {/*    flexDirection:`row`,*/}
        {/*    flexWrap:`wrap`,*/}
        {/*    justifyContent: `space-between`,*/}
        {/*    alignItems: `self`,*/}
        {/*    // alignContent: `space-between`*/}
        {/*      }}*/}
        {/*>*/}
        <div>
            <div>
                <AwesomeSlider animation="cubeAnimation">

                    {/*// This randomizer doesn't work so far*/}
                    {/*{shuffledDivs.map((div) => (*/}
                    {/*    <div key={div.id} style={{backgroundColor: '#060606', borderRadius:`50px`}}>*/}
                    {/*        <div>*/}
                    {/*            <img src={"div.src"} style={{width: `400px`, height: `auto`, borderRadius: `15px`}} alt="WTF" />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*))}*/}
                    <div style={{backgroundColor: '#060606', borderRadius:`50px`}}>
                        <img style={{width: `400px`, height: `auto`, borderRadius: `15px`}} src={meLookingMuc} alt={''}/>
                    </div>
                    <div style={{backgroundColor: '#060606', borderRadius:`50px`}}>
                        <img style={{width: `400px`, height: `auto`, borderRadius: `15px`}} src={Cern} alt={''}/>
                    </div>
                    <div style={{backgroundColor: '#060606', borderRadius:`50px`}}>
                        <img style={{width: `400px`, height: `auto`, borderRadius: `15px`}} src={GenovaNervi} alt={''}/>
                    </div>
                    <div style={{backgroundColor: '#060606', borderRadius:`50px`}}>
                        <img style={{width: `400px`, height: `auto`, borderRadius: `15px`}} src={meYellow} alt={''}/>
                    </div>
                    {/*<div style={{backgroundColor: '#060606', borderRadius:`50px`}}>*/}
                    {/*    <img style={{width: `400px`, height: `auto`, borderRadius: `15px`}} src={meChilling} alt={''}/>*/}
                    {/*</div>*/}
                    <div style={{backgroundColor: '#060606', borderRadius:`50px`}}>
                        <img style={{width: `400px`, height: `auto`, borderRadius: `15px`}} src={meMasked} alt={''}/>
                    </div>
                    <div style={{backgroundColor: '#060606', borderRadius:`50px`}}>
                        <img style={{width: `400px`, height: `auto`, borderRadius: `15px`}} src={meLooking} alt={''}/>
                    </div>
                    <div style={{backgroundColor: '#060606', borderRadius:`50px`}}>
                        <img style={{width: `400px`, height: `auto`, borderRadius: `15px`}} src={meChilling1} alt={''}/>
                    </div>
                
                    {/*<div style={{backgroundColor: '#060606', borderRadius:`50px`}}>*/}
                    {/*    <img style={{width: `400px`, height: `auto`, borderRadius: `15px`}} src={meFucking} alt={''}/>*/}
                    {/*</div>*/}
                </AwesomeSlider>
            </div>

        {/*<div style={{textAlign:`center`, padding:`0`}}>*/}
        {/*    <SceneButton />*/}
        {/*</div>*/}
        </div>


            {/*<img*/}
            {/*    style={{width: `200px`, height: `200px`, margin: `25px`,*/}
            {/*WebkitBoxReflect: `below 4px linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.1))`}}*/}
            {/*     src={me}*/}
            {/*     alt={'actually, I often look up on pictures somehow'}*/}
            {/*/>*/}


        {/*</div>*/}
        {/*<div style={{*/}
        {/*    display:`flex`,*/}
        {/*    flexDirection:`row`,*/}
        {/*    flexWrap:`wrap`,*/}
        {/*    justifyContent: `space-between`,*/}
        {/*    alignItems:`stretch`,*/}
        {/*    // alignContent: `space-between`*/}
        {/*      }}*/}
        {/*>*/}

        {/*    <span>*/}
        {/*        <h1 style={{margin: `0 auto`, textAlign:`center`, padding:`0`}}>*/}
        {/*            Here I structure*/}
        {/*        </h1>*/}
        {/*        <h1 style={{margin: `0 auto`, padding:`0`}} className={'gradient'}>*/}
        {/*            things*/}
        {/*        </h1>*/}
        {/*    </span>*/}

            {/*<span>*/}
            {/*    <Scene2 style={{width:`500px`, height:`auto`}}/>*/}
            {/*</span>*/}

            {/*<div style={{position:`relative`, width:`800px` ,margin: `0`, padding:`0`, border:`0`}}>*/}
            {/*    <SceneButton />*/}
            {/*</div>*/}

            {/*<div>*/}
            {/*    <img*/}
            {/*        style={{width: `310px`, height: `auto`, color: `transparent`,*/}
            {/*            WebkitBoxReflect: `below 4px linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.1))`}}*/}
            {/*        src={handsquare}*/}
            {/*        alt={'hello'}*/}
            {/*    />*/}
            {/*</div>*/}

            {/*<img*/}
            {/*    style={{width: `250px`, height: `auto`, margin: `25px`, color: `transparent`,*/}
            {/*WebkitBoxReflect: `below 4px linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.1))`}}*/}
            {/*     src={ekb}*/}
            {/*     alt={''}*/}
            {/*/>*/}

        {/*</div>*/}

        <br />
        <br />
        <br />
        {/*Adding about to the top*/}
        <div style={{textAlign:'center'}}>
            <h2>About</h2>

            Currently Solutions Architect at Deloitte-NVIDIA Alliance for High Performance Computing.
            <br />
            
            Focus on simulations, 3D and geometric deep learning.
            <br/>
            <br/>
            Working with <a href="https://developer.nvidia.com/modulus" target="_blank" rel="noopener noreferrer">NVIDIA Modulus</a> and <a href="https://developer.nvidia.com/kaolin" target="_blank" rel="noopener noreferrer">Omniverse</a>.
            <br/>
            It helps companies and researchers better understand and simulate real world processes.
            <br />
            Create <Link to="/projects/2020-05-05-photogrammetry-iphone-SE/">photogrammetry</Link> and <a href="https://stately-pika-10b87a.netlify.app/" target="_blank" rel="noopener noreferrer">neural fields</a> models.
            <br />
           

            Researched Graph Neural Networks at <a href="https://www2.deloitte.com/de/de/pages/innovation/topics/neuroscience-institute-overview.html" target="_blank"
                                                        rel="noopener noreferrer">Deloitte Neuroscience Institute</a>.
            <br />
            <br />
            <Collapsible title="Interested in">
                <div>
                    Non-linear systems
                    <br />
                    Multi-agent autonomous systems
                    <br />
                    Software engineering
                    <br />
                    Management of science projects
                </div>
            </Collapsible>
            <br />
            <br />
            Physically in Munich, in the Alps or in Moscow.
            <br />
            Telegram <a href="https://t.me/messelinks" target="_blank" rel="noopener noreferrer">channel</a> storing tech links, NVIDIA tools and neuroscience.
            
            <br />
            <Link to="/maps/where-to-go/">Travel around</Link>.
            <br />
            <br />
            Use <a href="https://t.me/userpav" target="_blank" rel="noopener noreferrer">this</a> to reach me out quickly.
        </div>
        {/*Till here*/}

            <div style={{textAlign:`center`, padding:`0`}}>
                <SceneButton />
            </div>

            <br />
            <br />
            <br />


        <div style={{textAlign:'center'}}>
            <div>
                <br/>
                <Collapsible title="Tools I use">
                    <div>
                        Python and Typescript.
                        <br/>
                        PyTorch, PyG, 
                        <br />
                        CUDA via CuPy or Numba
                        <br/>
                        React or Svelte
                        <br />
                        AWS or Azure
                        <br />
                        Rhino and Blender
                        {/*<br />*/}
                        {/*Worked with computer vision for a while.*/}
                        {/*<br />*/}
                        {/*Researched Generative Design and its applications on my Bachelor.*/}
                        {/*<br />*/}
                        {/*Built agent-based models for BMW and Siemens as part of my Thesis.*/}
                        {/*<br />*/}
                    </div>
                </Collapsible>

                
                <br />


                <Collapsible title="Academic Degrees:">
                    <div>
                        <br />
                        B.Sc SPbSTU (Institute for Computer Science and Technology)
                        [Thesis at TU Munich with BMW and OECC]
                        <br />
                        <br />
                        M.Sc HSE (Faculty of Computer Science)
                        {/*    links here   */}
                        <br />
                        <br />
                        M.Sc Politecnico di Milano (Electronics, Information Technology, and Bioengineering (DEIB))
                    </div>
                </Collapsible>
                
                <br />
                {/* <Collapsible title="What I support">
                    <div>
                        <br />
                        <ul>
                            <li>
                                <h2>Browser Based</h2>
                                <p>I currently (2021) think that browser can be the only app for an average user</p>
                                <p>Have used webgl and babylon to create  visualizations, games and browsers are cool to work with</p>
                                <p>WebGPU is cool</p>
                                <p>WebAssembly is the future of low latency applications on the web</p>
                            </li>
                            
                            <li>
                                <h2>Open Source</h2>
                                <p>I believe in the power of open source to drive innovation and collaboration. But not like Yann Lecun.
                                    Try to contribute to open-source projects and believe for their importance in the tech industry.</p>
                                    <p>Meanwhile understand why there still need to have an enterprise solution even around open-source stack, sometimes problems need to be solved fast and 
                                        reliable in business, only possible when money involved
                                    </p>
                            </li>   

                            <li>
                                <h2>Neural Operators</h2>
                                <p>Almost everything is a function in space and time, so if this basically maps functions to functions you treat whatever you want as a function</p>
                            </li>           

                            <li>
                                <h2>WebGL</h2>
                                <p>I believe in power of WebGL to create cool experiences on the web.</p>
                                <p>Have used webgl and babylon to create visualizations, games. Browsers are cool to work with</p>

                            </li>

                        

                            <li>
                                <h2>No conda</h2>
                                <p>Conda is only messing things up, work on hardware with less abstractions possible</p>
                            </li>

                        </ul>
                    </div>
                </Collapsible> */}


                <br/>
                <i>This website is a simple tech blog & testing place to keep up with web dev skills.</i>
                <br />
                Here is my <Link to="/resume/">Resume</Link> (may be outdated)
                <br />
                <p/>
                Random old projects stored <Link to="/projects/">in the list</Link>.
                <br/>
                <br/>
                Here is my <a href="mailto:pavli.main@gmail.com">email</a>, if you want to reach me oficially.
                <br/>
                <br/>

                If you want to send me money for some reason, use this:
                <br />
               
                <Collapsible title="Addresses">
                    <div>
                        <strong>BTC:</strong> bc1qsskapgen64uw50jkyk330yuhp6fdnv6lcwtkfw
                        <br/>
                        <strong>USDT(trc20):</strong> TWC3pCuY3raWjGC3MEqs2hvwrG96WmHhki
                        <br/>
                        <br/>
                        <strong>USDT(erc20):</strong> 0xe9d3d03fdca0db8b5bc619c41bf7be3258781468
                        <br/>
                        <strong>ETH:</strong> 0xb094b029741991d78d109b734760ad80ac5d23ef
                    </div>
                </Collapsible>



                <br />
                <br />
                Built this site myself with 💜, Typescript, GraphQL and 
                <a href="https://www.gatsbyjs.com/" target="_blank" rel="noopener noreferrer">Gatsby</a>.
                <br />
                Based on React.
                <br />
                Here is a full <a href="./projects/2021-04-01-this-website/" target="_blank"
                                  rel="noopener noreferrer canonical">
                tech stack</a>.
            </div>
            <br />
            <br />

            {/*// separate pic from Muc*/}
            {/*<div style={{ maxWidth: `300px`, margin: `0 auto` }}>*/}
            {/*    <ProfilePic />*/}
        </div>


 {/*               <div style={{textAlign:'center'}}>*/}
 {/*           <h2>About</h2>*/}

 {/*               Currently I'm Solutions Architect in Deloitte-NVIDIA Alliance for Supercomputing.*/}
 {/*               <br />*/}

 {/*               I work with physics and geometric machine learning frameworks such*/}
 {/*               as <a href="https://developer.nvidia.com/modulus" target="_blank" rel="noopener noreferrer">Modulus</a> and <a href="https://developer.nvidia.com/kaolin" target="_blank"*/}
 {/*                      rel="noopener noreferrer">Kaolin</a>.*/}

 {/*               <br />*/}
 {/*               It helps companies and researchers to better simulate and understand the real world.*/}
 {/*               <br />*/}
 {/*               <br />*/}

 {/*               I also research Graph Neural Networks at <a href="https://www2.deloitte.com/de/de/pages/innovation/topics/neuroscience-institute-overview.html" target="_blank"*/}
 {/*                                                                 rel="noopener noreferrer">Deloitte Neuroscience Institute</a>.*/}
 {/*               <br />*/}
 {/*               Before I was working with cooperative and decentralised systems.*/}
 {/*               <br />*/}
 {/*               <br />*/}
 {/*           Use mostly Python, Typescript or JS.*/}
 {/*           <br/>*/}
 {/*           All modeling and visualisations I make with Rhino or Blender.*/}
 {/*           <br />*/}
 {/*           Worked with computer vision for a while.*/}
 {/*           <br />*/}
 {/*           Researched Generative Design and its applications on my Bachelor.*/}
 {/*           <br />*/}
 {/*           Built agent-based models for BMW and Siemens as part of my Thesis.*/}
 {/*           <br />*/}
 {/*           <br />*/}
 {/*           B.Sc Cybernetics, SPbSTU (Thesis completed at TU Munich together with BMW and OECC)*/}
 {/*           <br />*/}
 {/*           M.Sc System and Program Engineering, HSE */}
 {/*           /!*    links here   *!/*/}
 {/*           <br />*/}

 {/*           <br />*/}
 {/*           <br />*/}
 {/*           Physically in Munich or in the Alps.*/}
 {/*           <br />*/}
 {/*           <Link to="/maps/where-to-go/">Travel around</Link>.*/}
 {/*           <br />*/}
 {/*           <br />*/}
 {/*           Use <a href="https://t.me/userpav" target="_blank" rel="noopener noreferrer">Telegram</a> to reach me*/}
 {/*           quickly.*/}
 {/*/!*or if you can help to finish OpenCV plugin*!/*/}
 {/*           /!*for <a*!/*/}
 {/*           /!*href="https://www.food4rhino.com/"*!/*/}
 {/*           /!*target="_blank" rel="noopener noreferrer"> Food4Rhino</a>.*!/*/}
 {/*               </div>*/}

        <br />
        <div style={{ maxWidth: `1000px`, margin: `0 auto` }}>
            <Scene />
        </div>
        
        <CookieConsent
            debug={false}
            style={{textAlign: `center`, font: `Helvetica Neue`, fontFamily:`Helvetica Neue, serif`,color:`rgb(166, 166, 166)` }}
            buttonText="Anyway"
            buttonStyle={{background: `#2626DB`,
                font: `Helvetica Neue`,
                color: 'rgb(166, 166, 166)',
                fontFamily:`Helvetica Neue, serif`,
                }}
        >
            Cookies.
            If needed, there is a <Link to="/cookie-policy/"><i>cookie policy</i></Link>
    </CookieConsent>
</Layout>


)

export default IndexPage